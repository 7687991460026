import {
  faCog,
  faEllipsis,
  faEllipsisV,
  faEnvelope,
  faIdBadge,
  faMessage,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCurrentUser } from "base/app";
import { getByIds, ServerObjCaches } from "base/get_by_ids";
import {
  ChatSession,
  INBOX_MESSAGE_TYPE_SESSION_UPDATE,
  INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE,
  INBOX_MESSAGE_TYPE_WA_INCOMING_MSG,
} from "base/ui/chat";
import { EditableTable } from "base/ui/editable_table";
import { GenericException } from "base/ui/errors";
import {
  SchemaObjectCreator,
  SimpleTableInput,
  TagsInput,
  UserBadge,
} from "base/ui/misc";
import { EmptyView, LoadingOverlay, LoadingView } from "base/ui/status";
import { SuggestedField } from "base/ui/suggested_field";
import { useOnScroll } from "base/ui/utils";
import { idToTitle, isObject, isString, last } from "base/utils";
import {
  defined,
  getRandomColor,
  obj2HTML,
  objToEl,
  sanitizeToId,
  useRerender,
  useRerenderWithValue,
} from "base/utils/common";
import {
  broadcaster,
  useBroadcastedState,
  useLocalStorageState,
} from "base/utils/events";
import React, { useEffect, useRef, useState } from "react";
import "./css/calendar.css";
import "./setupTailwind";
import { NumberBadge } from "./components/ui/commonUI";
import { Popup } from "base/ui/popups";
import {
  AddTagsWithValues,
  SearchTagsWithEsValues,
  ShowTagsWithValues,
  TagsWithTypesEditor,
} from "./common";
import {
  EditTicketData,
  fetchUsersByIdsOnTickets,
  TicketsList,
} from "./tickets";
import Clipboard from "base/utils/clipboard";
import { MILLIS_IN_MINUTE } from "base/constants";
import { showSelectTemplatePopUp } from "./broadcast/BroadcastHelper";
import ResponsivePopup from "../components/ui/ResponsivePopup";
import CreateWaTemplate from "./broadcast/CreateWaTemplate";

/*
  there is a check box - is staff to add roles,
*/
function EditEmployee({ admin_org_user, org_user, updates, org }) {
  const [is_staff, setIsStaff] = useState(
    Object.keys(org_user.roles || {}).length > 0
  );

  if (!admin_org_user?.roles.admin) {
    return <div className="tw-text-red-500">No Permission</div>;
  }

  return (
    <fieldset
      className={`tw-flex tw-flex-col tw-gap-2 ${
        is_staff ? "" : "tw-border-0"
      }`}
    >
      <legend className={`tw-flex tw-gap-2 tw-text-sm tw-ml-2`}>
        <input
          className="w3-check"
          type="checkbox"
          checked={is_staff}
          disabled={Object.keys(org_user.roles || {}).length > 0}
          onChange={(e) => {
            setIsStaff(e.target.checked);
          }}
        />
        <div>Is Staff</div>
      </legend>
      {is_staff ? (
        <div className="tw-space-y-2 tw-p-2">
          <div>Roles</div>
          <div className="tw-text-grey-400 tw-font-sm">
            Standard roles: admin, manager, agent
          </div>
          <SimpleTableInput
            rows={Object.entries(org_user.roles || { "": ["*"] }).map(
              ([role, permissions]) => [role, permissions.join(", ")]
            )}
            nCols={2}
            placeholders={["Role", "Permissions"]}
            onAction={(action, _row, _rows) => {
              updates.roles = _rows;
            }}
          />
          <SuggestedField
            props={{
              show_results_on_render: true,
              endpoint: `/api/admin/org/${org._id}/users?action=search`,
              params: { is_staff: true },
              results_key_path: "org_users",
              title_format: "{name}",
              max_selections: 1,
              placeholder: "Employee Manager",
              search_result_className:
                "!tw-font-normal tw-mt-2 !tw-shadow-none tw-divide-y tw-pl-2",
            }}
            selected={org_user.manager_user && [org_user.manager_user]}
            onSelect={(manager_users) => {
              updates.manager_user_id = manager_users[0]?.user_id || "";
            }}
          />
        </div>
      ) : null}
    </fieldset>
  );
}

function AddUserNameAndPhoneNumber({ org_user, updates }) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <input
        type="text"
        className="w3-input"
        defaultValue={org_user.name || ""}
        onChange={(e) => {
          updates.name = e.target.value;
        }}
        placeholder="Name"
      />
      <input
        type="text"
        className="w3-input"
        defaultValue={org_user.phone_number || ""}
        onChange={(e) => {
          updates.phone_number = e.target.value;
        }}
        pattern="[0-9]{8, 13}"
        placeholder="Phone Number"
      />
    </div>
  );
}

function DataColumnsEditor({ org_id, data_columns: _data_columns, onUpdated }) {
  const [data_columns, setDataColumns] = useState(null);
  const [updated_data_columns, setUpdatedDataColumns] = useState(null);

  useEffect(() => {
    setDataColumns(JSON.parse(JSON.stringify(_data_columns)));
  }, [_data_columns]);

  const doUpdate = () => {
    let to_update_list = [];
    for (let col of updated_data_columns) {
      to_update_list.push({
        key: sanitizeToId(col.key),
        schema: col.schema?.startsWith?.("{")
          ? JSON.parse(col.schema)
          : col.schema,
      });
    }
    axios
      .post(`/api/admin/org/${org_id}/users?action=update_data_columns`, {
        data_columns: to_update_list,
      })
      .then((resp) => {
        resp.data.data_columns && onUpdated(resp.data.data_columns);
        resp.data.errors && GenericException.showPopup(resp.data.errors);
      });
  };
  if (!data_columns) return <LoadingView />;
  return (
    <div className="tw-p-4">
      <SchemaObjectCreator
        schema={{
          type: "array",
          items: {
            type: "object",
            properties: {
              key: {
                type: "string",
                title: "Column Key",
              },
              schema: {
                type: "string",
                title: "schema",
                description:
                  "string, number, boolean, object, array, openapi schema",
                maxLength: 100,
                default: "string",
              },
            },
          },
        }}
        data={data_columns} // deep copy
        onUpdate={(_data_columns) => {
          setUpdatedDataColumns(_data_columns);
        }}
      />
      <div className="tw-mt-4 tw-flex">
        {updated_data_columns?.length ? (
          <div
            className="tw-px-2 tw-py-1 tw-bg-blue-500 tw-text-white tw-rounded-md tw-cursor-pointer"
            onClick={doUpdate}
          >
            Update
          </div>
        ) : null}
      </div>
    </div>
  );
}

/*
	OrgUsers
	filters -> search_text, is_staff, subscribers
*/
function OrgUsersPage({ org_id: _org_id }) {
  const [admin_org_user, setAdminOrgUser] = useState(null);
  const [org_users, setOrgUsers] = useState([]);
  const [users_count, setOrgUsersCount] = useState(null);
  const [org_id] = useLocalStorageState("org_id", _org_id);
  const [tag_types, setTagTypes] = useBroadcastedState("tag_types");
  const [data_columns, setDataColumns] = useBroadcastedState("data_columns");
  const ctx = useRef({ filters: {} }).current;
  const rerender = useRerender();
  const [filters_updated, setFiltersUpdated] = useRerenderWithValue();
  const [org, setOrg] = useBroadcastedState("org");
  const usersRef = useRef(null);
  const [sample_template, setSampleTemplate] = useState();

  const onOrgUsersSearchReponse = (resp) => {
    ctx.has_more_users = resp.data.has_more_users;
    ctx.next_users_cursor = resp.data.next_users_cursor;
    setOrgUsersCount(resp.data.users_count);
    ctx.org_users = ctx.org_users || [];
    ctx.org_users.push(...resp.data.org_users);
    setOrgUsers([...ctx.org_users]);
  };

  /* init */
  useEffect(() => {
    if (ctx.is_loading) return;
    ctx.is_loading = true;
    rerender();
    axios
      .post(`/api/admin/org/${org_id}/users`)
      .then((resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors);
          return;
        }
        resp.data.org && setOrg(resp.data.org);
        resp.data.admin_org_user && setAdminOrgUser(resp.data.admin_org_user);
        resp.data.tag_types && setTagTypes(resp.data.tag_types);
        resp.data.data_columns && setDataColumns(resp.data.data_columns);
        onOrgUsersSearchReponse(resp);
      })
      .finally(() => {
        ctx.is_loading = false;
        rerender();
      });
  }, []);

  /* fetch more users */
  const searchAndFetchMoreUsers = () => {
    if (ctx.has_more_users === false || ctx.is_loading) return;
    ctx.is_loading = true;
    rerender();
    const params = {
      search_text: ctx.filters.search_text,
      tags: ctx.filters.tags,
      cursor: ctx.next_users_cursor,
    };
    axios
      .post(`/api/admin/org/${org_id}/users?action=search`, params)
      .then((resp) => {
        if (resp.data.errors) {
          GenericException.showPopup(resp.data.errors);
          return;
        }
        onOrgUsersSearchReponse(resp);
      })
      .finally(() => {
        ctx.is_loading = false;
        rerender();
      });
  };

  /* refetch when filters changed */
  useEffect(() => {
    ctx.next_users_cursor = null;
    ctx.has_more_users = true;
    ctx.org_users = [];
    searchAndFetchMoreUsers();
  }, [filters_updated]);

  /* auto load more on scroll */
  useOnScroll(
    usersRef.current,
    (percent) => {
      percent === 100 && searchAndFetchMoreUsers();
    },
    [org_users]
  );

  /* handlers */
  const doCreateUser = () => {
    const new_row = { __is_new: true, __is_editing: true };
    setOrgUsers([new_row, ...org_users]);
  };

  const showTagTypesEditorPopup = () => {
    var updated_tag_types = null;
    Popup.show(
      "Update Tag Types",
      <div className="tw-m-2 tw-mb-16">
        <TagsWithTypesEditor
          tag_types={tag_types}
          onUpdated={(tag_types) => (updated_tag_types = tag_types)}
        />
      </div>,
      {
        ok_button: "Update",
        cb: (action) => {
          action &&
            axios
              .post(`/api/admin/org/${org_id}/users?action=update_tag_types`, {
                tag_types: updated_tag_types,
              })
              .then((resp) => {
                resp.data.tag_types && setTagTypes(resp.data.tag_types);
              });
        },
      }
    );
  };

  const showDataColumnsEditor = () => {
    var popup = Popup.show(
      "Data Columns",
      <DataColumnsEditor
        org_id={org_id}
        data_columns={data_columns}
        onUpdated={(data_columns) => {
          setDataColumns(data_columns);
          popup.close();
        }}
      />
    );
  };

  const onUsersSettingsClick = (evt) => {
    Popup.showContextMenu(
      evt.currentTarget,
      <div className="tw-p-2 tw-flex tw-flex-col tw-space-y-2">
        <div onClick={showTagTypesEditorPopup}>Edit Tags</div>
        <div onClick={showDataColumnsEditor}>Edit Data Columns</div>
      </div>
    );
  };

  if (!ctx.org_users) return <LoadingOverlay />;
  return (
    <div className="tw-p-4">
      <div className="hflex tw-flex-wrap tw-gap-x-4 tw-gap-y-2">
        <div>
          <div className="tw-text-lg tw-font-semibold">Users</div>
          <div className="tw-text-xs tw-text-gray-500 tw-mt-2"></div>
        </div>
        <div className="tw-ml-auto tw-flex tw-gap-4 tw-items-center">
          <button onClick={onUsersSettingsClick} className="tw-mr-4">
            <FontAwesomeIcon icon={faCog} />
          </button>
          <button
            className="btn-primary tw-text-xs tw-shrink-0"
            onClick={() => doCreateUser()}
          >
            + Create User
          </button>
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-mt-6 tw-items-center">
        <div className="tw-flex-grow">
          <SearchTagsWithEsValues
            tag_types={tag_types}
            onTextSearch={(search_text) => {
              ctx.filters.search_text = search_text;
              setFiltersUpdated();
            }}
            onTagsChange={(tags) => {
              ctx.filters.tags = tags;
              setFiltersUpdated();
            }}
          />
        </div>
        {users_count?.value ? (
          <div className="tw-inline-block tw-ml-auto tw-text-sm tw-rounded-lg tw-px-2 tw-py-1 tw-flex tw-gap-4">
            <div className="tw-font-bold tw-text-center">
              {users_count.relation.startsWith("gt") ? ">" : null}{" "}
              {users_count.value} {" Users"}
            </div>
            <span
              onClick={() => {
                showSelectTemplatePopUp(
                  org,
                  `${users_count.value}  Users`,
                  ctx.filters,
                  setSampleTemplate
                );
              }}
              className="tw-cursor-pointer"
            >
              <FontAwesomeIcon icon={faBullhorn} className="tw-text-lg" />
            </span>
          </div>
        ) : null}
      </div>
      <div
        className={"tw-mt-4 tw-overflow-y-auto tw-w-full tw-pt-4"}
        ref={usersRef}
      >
        <OrgUsersList org_id={org_id} org_users={org_users} />
      </div>
      <ResponsivePopup
        show={sample_template}
        title={"Create New Template"}
        onClose={() => setSampleTemplate(undefined)}
        is_full_screen={true}
      >
        <CreateWaTemplate
          wa_business_number={org.wa_business_number}
          template={sample_template}
          onClose={(is_success) => {
            setNewTemplate(undefined);
            if (is_success) {
              showSelectTemplatePopUp(
                org,
                `${users_count.value}  Users Selected`,
                ctx.filters,
                setSampleTemplate
              );
            }
          }}
        />
      </ResponsivePopup>
    </div>
  );
}

function OrgUsersList({
  org_id,
  org_users,
  callbacks,
  actions,
  L = 1024,
  hide_columns,
}) {
  const [show_wallet_column, setShowWalletColumn] = useState(false);
  const [tag_types, setTagTypes] = useBroadcastedState("tag_types");
  const [admin_org_user, setAdminOrgUser] =
    useBroadcastedState("admin_org_user");
  const [data_columns, setDataColumns] = useBroadcastedState("data_columns");
  const [org, setOrg] = useBroadcastedState("org");
  const ctx = useRef({}).current;
  const rerender = useRerender();

  /* fetch necessary fields to render*/
  useEffect(() => {
    let fields_to_fetch = [];
    if (!tag_types) fields_to_fetch.push("tag_types");
    if (!admin_org_user) fields_to_fetch.push("admin_org_user");
    if (!org || org._id !== org_id) fields_to_fetch.push("org");
    if (!data_columns) fields_to_fetch.push("data_columns");
    if (fields_to_fetch.length) {
      axios
        .post("/api/admin/org/" + org_id + "/users", {
          fields: fields_to_fetch,
        })
        .then((resp) => {
          resp.data.tag_types && setTagTypes(resp.data.tag_types);
          resp.data.admin_org_user && setAdminOrgUser(resp.data.admin_org_user);
          resp.data.org && setOrg(resp.data.org);
          resp.data.data_columns && setDataColumns(resp.data.data_columns);
        });
    }
  }, [org_id]);

  /* fetch managers and aditional data */
  useEffect(() => {
    ctx.is_fetching_users = true;
    let user_ids_to_fetch = [];
    org_users.forEach((org_user) => {
      org_user.manager_user_id &&
        user_ids_to_fetch.push(org_user.manager_user_id);
      org_user.org_id = org_id;
    });

    getByIds({ user_ids: user_ids_to_fetch }).then((data) => {
      for (let org_user of org_users) {
        org_user.manager_user = data.users[org_user.manager_user_id];
      }
      ctx.is_fetching_users = false;
      rerender();
    });
    setShowWalletColumn(org_users.find((org_user) => org_user.wallet));
  }, [org_id, org_users]);

  if (ctx.is_fetching_users) return <LoadingView height={"400px"} />;
  const openChat = (org_user) => {
    ChatSession.open(`wcs_${org_user.user_id}_${org.wa_business_number}`, {
      bottomStatusIndicator: (session_data) => {
        return (
          <OrgUserChatSupportButtons
            org_id={org_id}
            user_id={org_user.user_id}
            session_data={session_data}
            onChatResolved={(cs_unseen_count) => {
              if (!cs_unseen_count) {
                org_user.wa_user && (org_user.wa_user.cs_unseen_count = 0);
                org_user.rerender?.(); // from editable table
              }
            }}
          />
        );
      },
      ...(OrgUsersPage.CHAT_OPTIONS || {}),
    });
  };

  const fetchAdditionalData = async (org_user) => {
    let manager_user_id = org_user?.manager_user_id;
    if (manager_user_id) {
      let cache = await getByIds({ user_ids: [manager_user_id] });
      org_user.manager_user = cache.users[manager_user_id];
    }
  };

  const moreOptionsClick = (org_user, evt) => {
    const addRemoveMoney = () => {
      let amount = parseFloat(
        window.prompt("Enter amount to add/remove from wallet", "0")
      );
      if (!amount) return;
      /* if invalid amount, show GenericException.showPopup({"Invalid Amount": amount}) */
      if (isNaN(amount))
        return GenericException.showPopup({ "Invalid Amount": amount });
      axios
        .post("/api/admin/org/" + org_id + "/users?action=deopsit_wallet", {
          amount: amount,
          user_id: org_user.user_id,
        })
        .then((resp) => {
          if (resp.data.errors) {
            GenericException.showPopup(resp.data.errors);
            return;
          }
          org_user.wallet = resp.data.deposit_wallet;
          org_user.rerender?.();
        });
    };

    const copyUserId = () => {
      Clipboard.copy(org_user._id);
      Popup.toast("Copied");
    };

    const removeUser = async () => {
      if (
        !window.confirm(
          `Are you sure you want to remove ${
            org_user.alt_name || org_user.name
          }?`
        )
      )
        return;
      const resp = await axios.post(
        `/api/admin/org/${org_id}/users?action=delete_user`,
        { user_id: org_user.user_id }
      );
      if (resp.data.errors) return GenericException.showPopup(resp.data.errors);
      if (resp.data.deleted) {
        org_user.__is_deleted = true;
        org_user.rerender?.();
      }
    };

    const showData = () => {
      Popup.show("Data", objToEl(org_user.data));
    };

    Popup.showContextMenu(
      evt.currentTarget,
      <div className="tw-divide-y">
        <div className="tw-p-2" onClick={addRemoveMoney}>
          Add/Refund
        </div>
        <div className="tw-p-2" onClick={copyUserId}>
          Copy User Id
        </div>
        <div className="tw-p-2" onClick={showData}>
          Show Data
        </div>
        <div className="tw-p-2" onClick={removeUser}>
          Remove User
        </div>
      </div>
    );
  };

  return (
    <EditableTable
      cols={[
        {
          header: "Name",
          render: (org_user) => {
            if (!org_user.user_id) return null;
            return (
              <div className="tw-text-sm">
                <UserBadge user={org_user} />
              </div>
            );
          },
          editor: (org_user, updates) => {
            if (!org_user.user_id)
              return (
                <AddUserNameAndPhoneNumber
                  org_user={org_user}
                  updates={updates}
                />
              );
            return (
              <input
                type="text"
                className="w3-input"
                defaultValue={org_user.alt_name || org_user.name}
                onChange={(e) => {
                  updates.name = e.target.value;
                }}
              />
            );
          },
          is_col_editable: true,
        },
        {
          title: "Tags",
          render: (org_user) => {
            let open_tickets = org_user.open_tickets || {};
            if (!org_user.tags && !Object.keys(open_tickets).length)
              return null;
            let open_unpaid_tickets = Object.entries(open_tickets).filter(
              ([ticket_id, quick_ticket_data]) => quick_ticket_data.amount_due
            );
            return (
              <div className="tw-text-sm">
                <div className="tw-flex tw-gap-2 tw-flex-wrap tw-font-medium">
                  {open_unpaid_tickets.length > 0 ? (
                    <div className="tw-bg-red-200 tw-px-2 tw-py-1 tw-rounded-full tw-text-red-500">
                      {open_unpaid_tickets.length} Unpaid Tickets
                    </div>
                  ) : null}
                  {Object.keys(open_tickets).length -
                    open_unpaid_tickets.length >
                  0 ? (
                    <div className="tw-bg-blue-200 tw-px-2 tw-py-1 tw-rounded-full tw-text-blue-500">
                      {Object.keys(open_tickets).length -
                        open_unpaid_tickets.length}{" "}
                      Open Tickets
                    </div>
                  ) : null}
                  {org_user.tags &&
                    Object.entries(org_user.tags).map(([tag, value]) => (
                      <div
                        className="tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full"
                        key={tag}
                        title={value}
                      >
                        {idToTitle(tag)}
                      </div>
                    ))}
                </div>
                {org_user.system_tags &&
                Object.keys(org_user.system_tags).length > 0 ? (
                  <div className="tw-border-t">
                    <div className="tw-flex tw-gap-2 tw-flex-wrap tw-font-medium">
                      {Object.entries(org_user.system_tags)?.map(
                        ([tag, value]) => (
                          <div
                            className="tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full"
                            key={tag}
                            title={value}
                          >
                            {tag}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : null}
                {!data_columns?.length &&
                org_user.data &&
                Object.keys(org_user.data).length > 0 ? (
                  <div className="tw-mt-2">{objToEl(org_user.data)}</div>
                ) : null}
              </div>
            );
          },
          editor: (org_user, updates) => {
            return (
              <>
                <div className="tw-mb-4">
                  <AddTagsWithValues
                    tag_types={tag_types}
                    selected={org_user.tags}
                    onTagsChange={(tags) => {
                      updates.tags = tags;
                    }}
                  />
                </div>
                {!data_columns?.length ? (
                  <div>
                    <div className="tw-text-sm tw-font-semibold">
                      Add/Remove Data
                    </div>
                    <SimpleTableInput
                      nCols={2}
                      rows={Object.entries(org_user.data || {})}
                      onAction={(action, _row, _rows) => {
                        updates.data = Object.fromEntries(_rows);
                      }}
                    />
                  </div>
                ) : null}
              </>
            );
          },
          is_col_editable: true,
        },
        !hide_columns?.has("role") && {
          /* Employee */
          title: "Roles/Permissions",
          render: (org_user) => {
            return (
              <div>
                {org_user.roles ? (
                  <div className="tw-flex tw-flex-row tw-flex-wrap tw-text-sm">
                    {Object.entries(org_user.roles).map(
                      ([role, permissions]) => {
                        return (
                          <div
                            key={role}
                            className="tw-p-1 tw-border tw-rounded-md tw-text-white tw-inline"
                            style={{ backgroundColor: getRandomColor(role) }}
                          >
                            {idToTitle(role)}
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : null}
                {org_user.manager_user ? (
                  <>
                    Manager:{" "}
                    <div className="tw-rounded-3xl tw-bg-gray-500 tw-px-2 tw-py-1 tw-inline tw-text-white">
                      {org_user.manager_user.name}
                    </div>
                  </>
                ) : null}
              </div>
            );
          },
          editor: (org_user, updates) => {
            return (
              <EditEmployee
                org_user={org_user}
                updates={updates}
                admin_org_user={admin_org_user}
                org={org}
              />
            );
          },
          is_col_editable: true,
        },
        show_wallet_column && {
          /* Wallet */
          header: "Wallet",
          render: (org_user) => {
            let wallet = org_user.wallet;
            if (!wallet) return null;
            return (
              <div className="tw-pr-2 tw-gap-1">
                {wallet.wallet_amount <= 0 ? "Credit Used:" : "Wallet:"}&nbsp;
                <span
                  className={`${
                    wallet.wallet_amount === 0
                      ? "tw-text-black"
                      : wallet.wallet_amount < 0
                      ? "tw-text-red-600"
                      : "tw-text-green-600"
                  } tw-text-sm`}
                >
                  {wallet.wallet_amount_str}
                </span>
                {wallet.minimum_wallet_amount &&
                  wallet.minimum_wallet_amount < 0 && (
                    <span className="tw-text-xs tw-px-2">
                      Credit:{" "}
                      <span className="tw-text-xs">
                        {wallet.credit_amount_str}
                      </span>
                    </span>
                  )}
              </div>
            );
          },
        },
        ...(data_columns?.map(({ key, schema }) => {
          return {
            title: idToTitle(key),
            render: (org_user) => {
              return org_user.data?.[key] ? objToEl(org_user.data[key]) : "";
            },
            editor: (org_user, updates) => {
              let val = org_user.data?.[key];
              val = val && JSON.parse(JSON.stringify(val)); // deep copy
              return (
                <SchemaObjectCreator
                  schema={schema}
                  data={val}
                  onUpdate={(_val) => {
                    updates.add_data = updates.add_data || {};
                    updates.add_data[key] = _val;
                  }}
                />
              );
            },
            is_col_editable: true,
          };
        }) || []),
      ]}
      L={L}
      actions={
        actions || [
          [
            (org_user) => (
              <div className="tw-relative tw-text-black tw-cursor-pointer tw-border tw-rounded-lg tw-px-2 tw-py-1">
                {org_user.wa_user?.cs_unseen_count > 0 ? (
                  <NumberBadge val={org_user.wa_user?.cs_unseen_count} />
                ) : null}
                <FontAwesomeIcon icon={faMessage} /> Chat
              </div>
            ),
            openChat,
          ],
          [
            <span
              key="view_more"
              className="tw-text-black tw-border tw-rounded-lg tw-px-2 tw-py-1"
            >
              Tickets
            </span>,
            (org_user) =>
              Popup.sideSheet(
                <UserFullDetails
                  org_id={org_id}
                  user_id={org_user.user_id}
                  org_user={org_user}
                />
              ),
          ],
          [
            <div className="tw-px-2 tw-py-1 tw-text-black" key="more">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>,
            moreOptionsClick,
          ],
        ]
      }
      rows={org_users}
      callbacks={{
        onRowClick: (org_user) => {
          Popup.sideSheet(
            <UserFullDetails
              org_id={org_id}
              user_id={org_user.user_id}
              org_user={org_user}
            />
          );
        },
        onUpdate: async (org_user, updates) => {
          let resp = null;
          let overrides = {};
          if (!org_user.user_id) {
            resp = await axios.post(
              `/api/admin/org/${org_id}/users?action=create`,
              { ...updates, ...overrides }
            );
          } else {
            resp = await axios.post(
              `/api/admin/org/${org_id}/user/${org_user.user_id}?action=update`,
              {
                ...updates,
                ...overrides,
              }
            );
          }
          await fetchAdditionalData(resp.data.org_user);
          return [resp.data.org_user, resp.data.errors];
        },
        ...(callbacks || {}),
      }}
      options={{
        class_names: {
          t: {
            row: "tw-shadow-md tw-border tw-rounded-md tw-p-2 tw-mb-2",
          },
          l: {
            row: "tw-p-2",
          },
        },
      }}
    />
  );
}

/* 
	Chat support buttons
	- Resolve chat
	- Toggle bot active
	- Force take over customer support
*/
function OrgUserChatSupportButtons({
  org_id,
  user_id,
  session_data,
  onChatResolved,
  wrapper = true,
}) {
  const wa_user = session_data.wa_user || {};
  const org_user = session_data.org_user || {};
  const [is_page_visible] = useBroadcastedState("is_page_visible", true);
  const [cs_unseen_count, setCsUnseenCount] = useState(wa_user.cs_unseen_count);

  const [cs_staff_id, setCsStaffId] = useState(wa_user.cs_staff_id);
  const [cs_staff_user, setCsStaffUser] = useState(null);
  const [cs_active_until, setCsActiveUntil] = useState(
    wa_user.cs_active_until || 0
  );

  const ctx = useRef({}).current;
  const user = useCurrentUser();

  const ping_cs_active = (force) => {
    if (
      wa_user.last_cs_ping_at &&
      wa_user.last_cs_ping_at > now_millis - 30 * 1000
    )
      return;
    wa_user.last_cs_ping_at = now_millis;
    const last_user_im_created_at = last(session_data.inbox_messages, (im) => {
      return (
        im.src_id === user_id && im._type === INBOX_MESSAGE_TYPE_WA_INCOMING_MSG
      );
    })?.created_at;

    axios
      .post(`/api/admin/org/${org_id}/user/${user_id}`, {
        action: "cs_active",
        force: force,
        last_user_im_created_at: last_user_im_created_at,
      })
      .then((resp) => {
        defined(resp.data.cs_unseen_count) &&
          setCsUnseenCount(resp.data.cs_unseen_count);
        setCsStaffId(resp.data.cs_staff_id);
        setCsActiveUntil(resp.data.cs_active_until);
      })
      .catch((err) => {
        wa_user.last_cs_ping_at = 0;
      });
  };

  useEffect(() => {
    if (!cs_staff_id) return;
    getByIds({ user_ids: [cs_staff_id] }).then((cache) => {
      setCsStaffUser(cache.users[cs_staff_id]);
    });
  }, [cs_staff_id]);

  const mark_cs_inactive = () => {
    axios
      .post(`/api/admin/org/${org_id}/user/${user_id}`, {
        action: "cs_inactive",
      })
      .then((resp) => {
        wa_user.last_cs_ping_at = 0;
        if (defined(resp.data.cs_unseen_count))
          setCsUnseenCount(resp.data.cs_unseen_count);
      });
  };

  const resolveChat = () => {
    if (ctx.is_resolving) return;
    ctx.is_resolving = true;
    axios
      .post(`/api/admin/org/${org_id}/user/${user_id}`, { action: "resolve" })
      .then((resp) => {
        setCsUnseenCount(resp.data.cs_unseen_count);
        onChatResolved && onChatResolved(resp.data.cs_unseen_count);
      })
      .finally(() => (ctx.is_resolving = false));
  };

  const _onCsChatActivity = (session_data) => {
    if (!session_data.session._id.startsWith("wcs_")) return;
    ctx.is_cs_active = true;
    ping_cs_active();
    setCsActiveUntil(new Date().getTime() + MILLIS_IN_MINUTE);
    if (ctx.cs_active_timer) clearInterval(ctx.cs_active_timer);
    /* ping every 10 minutes */
    ctx.cs_active_timer = setInterval(
      () => ctx.is_cs_active && ping_cs_active(),
      MILLIS_IN_MINUTE
    );
  };

  const toggleBotActive = () => {
    let now_millis = new Date().getTime();
    if (cs_active_until > now_millis) {
      mark_cs_inactive();
      setCsActiveUntil(now_millis);
      ctx.is_cs_active = false;
    } else {
      ping_cs_active();
      setCsActiveUntil(now_millis + MILLIS_IN_MINUTE);
      ctx.is_cs_active = true;
    }
  };

  useEffect(() => {
    /* we updating this so alt name is reflected in chat messages and not from actual user*/
    const cs_user = session_data.org_user?.user;
    if (cs_user) ServerObjCaches["users"][cs_user._id] = cs_user;

    const onCsChatTyping = (session_data, el) =>
      _onCsChatActivity(session_data);
    const onCsMessageSent = (im, session_data) =>
      _onCsChatActivity(session_data);
    broadcaster.add_event_listener("chat:typing", onCsChatTyping);
    broadcaster.add_event_listener("chat:message_sent", onCsMessageSent);
    broadcaster.add_event_listener("chat:cs_activity", _onCsChatActivity);

    const onSessionUpdate = (im) => {
      if (!im.inbox_id === session_data.session._id) return;
      if (
        (im._type === INBOX_MESSAGE_TYPE_SESSION_UPDATE ||
          im._type === INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE) &&
        im.data
      ) {
        defined(im.data.cs_unseen_count) &&
          setCsUnseenCount(im.data.cs_unseen_count);
        im.data.cs_active_until &&
          setCsActiveUntil((wa_user.cs_active_until = im.data.cs_active_until));
        im.data.cs_staff_id &&
          setCsStaffId((wa_user.cs_staff_id = im.data.cs_staff_id));
      }
    };
    broadcaster.add_event_listener(
      `im:${session_data.session._id}`,
      onSessionUpdate
    );
    return () => {
      clearInterval(ctx.cs_active_timer);
      broadcaster.remove_event_listener("chat:typing", onCsChatTyping);
      broadcaster.remove_event_listener("chat:message_sent", onCsMessageSent);
      broadcaster.remove_event_listener("chat:cs_activity", _onCsChatActivity);
      broadcaster.remove_event_listener(
        `im:${session_data.session._id}`,
        onSessionUpdate
      );
    };
  }, []);

  useEffect(() => {
    ctx.is_cs_active && ping_cs_active();
  }, [is_page_visible]);

  const now_millis = new Date().getTime();
  const buttons = (
    <>
      {cs_unseen_count ? (
        <div
          className="tw-px-2 tw-py-1 tw-bg-orange-500 tw-grow"
          onClick={resolveChat}
        >
          ☐ Resolve Chat
        </div>
      ) : (
        org_user && (
          <div
            className="tw-px-2 tw-grow tw-bg-black tw-py-1"
            onClick={() =>
              Popup.sideSheet(
                <UserFullDetails org_id={org_id} user_id={user_id} />,
                { style: { zIndex: 50 } }
              )
            }
          >
            👤 Manage/Tickets
          </div>
        )
      )}
      <div
        className={`tw-px-2 tw-grow tw-py-1 tw-bg-blue-500 ${
          !cs_unseen_count ? "tw-min-w-[30%]" : ""
        }`}
        onClick={() => toggleBotActive()}
      >
        {cs_active_until > now_millis ? "☐" : "✅"} BOT
      </div>
      {
        /*
						cs active leases the chat for 7 minutes and updates every 1.5 minutes
						if the chat was leased by another user less than 4 minutes ago, show force button
					*/
        cs_active_until > now_millis + 3 * MILLIS_IN_MINUTE &&
        cs_staff_user &&
        user &&
        cs_staff_user._id !== user._id ? (
          <div className="tw-bg-red-600 tw-flex tw-flex-row tw-clear-both tw-flex-grow tw-p-1">
            <div className="tw-pl-2">
              {cs_staff_user.name} is handling this chat
            </div>
            <div
              className="tw-px-4 tw-ml-auto tw-border tw-content-center"
              onClick={() => ping_cs_active(true)}
            >
              Force
            </div>
          </div> /* change it to tailwind */
        ) : null
      }
    </>
  );
  if (!wrapper) return buttons;
  return (
    <div className="tw-text-sm tw-flex tw-flex-row tw-flex-wrap tw-bg-gray-500 tw-text-white tw-items-center tw-cursor-pointer">
      {buttons}
    </div>
  );
}

function UserFullDetails({ org_id, user_id, org_user: _org_user }) {
  const [tickets, setTickets] = useState(null);
  const [org_user, setOrgUser] = useState(null);
  const ctx = useRef({ tickets: [] }).current;

  /* load tickets */
  useEffect(() => {
    if (ctx.is_loading) return;
    ctx.is_loading = true;
    axios
      .post(`/api/admin/org/${org_id}/user/${user_id}`)
      .then((resp) => {
        resp.data.tickets && ctx.tickets.push(...resp.data.tickets);
        setOrgUser(resp.data.org_user);
        _org_user &&
          resp.data.org_user &&
          Object.assign(_org_user, resp.data.org_user); // update to latest data
        setTickets([...ctx.tickets]);
      })
      .finally(() => {
        ctx.is_loading = false;
      });
  }, []);

  const doCreateTicket = () => {
    var popup = Popup.sideSheet(
      <div className="tw-p-2">
        <div className="tw-text-lg tw-font-semibold tw-p-2">
          Create New Ticket
        </div>
        <EditTicketData
          ticket={{ org_id: org_id, user: org_user }}
          onUpdate={(ticket) => {
            ctx.tickets.unshift(ticket); // add this ticket to the top
            fetchUsersByIdsOnTickets([ticket]).then(() =>
              setTickets([...ctx.tickets])
            );
            popup.close();
          }}
        />
      </div>,
      { style: { zIndex: 50 } }
    );
  };

  if (!org_user) return <LoadingView height={"400px"} />;

  return (
    <div className="tw-p-4">
      <div className="tw-text-xl tw-mb-4">User Details</div>
      <OrgUsersList
        org_users={[org_user]}
        org_id={org_id}
        callbacks={{ onRowClick: null }}
        actions={[]}
      />
      {/*tickets */}
      <div className="tw-text-xl tw-mt-4 tw-border-t tw-py-2 tw-mb-4 ">
        <div className="tw-flex tw-flex-row">
          <div>Tickets</div>
          <div className="tw-ml-auto tw-flex tw-gap-4" onClick={doCreateTicket}>
            <button className="btn-primary tw-text-xs tw-shrink-0">
              + Create Ticket
            </button>
          </div>
        </div>
      </div>
      {tickets?.length ? (
        <TicketsList tickets={tickets} />
      ) : (
        <EmptyView height={"200px"} title="No tickets" />
      )}
    </div>
  );
}

export {
  OrgUsersPage,
  OrgUserChatSupportButtons,
  UserFullDetails,
  OrgUsersList,
};
