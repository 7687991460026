import { faBars, faBell, faCircleUser, faCloudDownload, faEnvelope, faMapLocationDot, faSpinner, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router';
import { useBroadcastedState } from 'base/utils/events';
import { isInStandaloneMode, requestAppInstall, requestWebPush } from 'base/utils/common';
import { CHECKIN_STATUS, useStaffCheckIn } from '../../useStaffLocation';
import SideSheet from '../ui/SideSheet';
import { DateView } from 'base/ui/date';
import { matchPath, useLocation } from 'react-router-dom';
import ResponsivePopup from '../ui/ResponsivePopup';
import RoutePlanStaffView from '../features/deliveryRoute/staff/RoutePlanStaffView';
import { getDisplayPrice } from '../../helpers/StoreHelper';
import { Popup } from 'base/ui/popups';
import { ReactComponent as LPCheckIcon } from "../../images/ic_loyaltypoints_check.svg"


const PointsInfo = () => {
  return (
    <div className='tw-p-4 tw-text-sm tw-text-gray-600'>
      <div className='tw-text-lg tw-font-bold tw-mb-2'>Points Wallet</div>
      <div className='tw-flex tw-items-center tw-mb-2'>
        You can accumulate points by completing orders for exciting rewards.
      </div>
    </div>
  )
}

function LPStorePopUp({ onContinue }) {
  return (
    <div className='tw-grid tw-grid-cols-1 tw-gap-2 tw-place-items-center tw-px-2 tw-py-4'>
      <LPCheckIcon style={{ width: 80, height: 80 }} />
      <h4 className='tw-text-center tw-leading-normal tw-whitespace-pre'>{"Buy with your loyalty points\non Points Store"}</h4>
      <button className='btn-primary'
        onClick={() => { onContinue() }}>
        Visit Loyalty Points Store</button>
    </div>
  )
}


const HeaderNav = ({user, onLogin, onShowChatSession, onShowUserProfile, className}) => {
    const [is_subscribed] = useBroadcastedState('web_push_subscription');
    const [is_push_supported] = useBroadcastedState('web_push_support');
    const [can_install] = useBroadcastedState('app_install_support');
    const {status: staff_checkin_status, timestamp, staffCheckIn, is_loading: is_checkin_loading, checkin_route_id} = useStaffCheckIn();
    const [show_menu, setShowMenu] = useState(false);
    const [screen] = useBroadcastedState("tw_screen_size");
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const home_path = matchPath('/', pathname);
    const app_route_path = matchPath(`/${checkin_route_id}`, pathname);
    const is_user_manager = (
      user?.roles && (
        user.roles.manager || user.roles.delivery_staff || user.roles.delivery_org_manager || user.roles.superadmin
      )
    );
    const is_shop_page = (home_path && checkin_route_id) || app_route_path;
    const [shop_page_points_wallet] = useBroadcastedState('points_wallet');
    const [show_route_plan, setShowRoutePlan] = useState(false)


    const showPointsInfo = (evt) => {
      if (shop_page_points_wallet.shop_url && shop_page_points_wallet.shop_url !== window.location.pathname) {
        const popUp = Popup.slideUp(<LPStorePopUp onContinue={() => {
          popUp.close()
          window.open(shop_page_points_wallet.shop_url);
        }} />)
        return
      }      
      Popup.showContextMenu(evt.currentTarget, <PointsInfo />);
    }
    const MenuItems = useCallback(() => (
      <>
        {
          is_user_manager && is_shop_page && (
            <>
              <div>
                <button onClick={staffCheckIn} className='tw-text-sm tw-bg-yellow-500 tw-px-4 tw-py-2 tw-rounded tw-text-black tw-w-full'
                  type='button'
                >
                  {is_checkin_loading ? <FontAwesomeIcon icon={faSpinner} className='tw-animate-spin tw-mr-2' /> : null}
                  {staff_checkin_status === CHECKIN_STATUS.CHECKED_IN ? 'Check Out' : 'Check In'}
                </button> 
                {staff_checkin_status === CHECKIN_STATUS.CHECKED_IN
                  && timestamp ? <div className='tw-text-xs tw-text-gray-500 tw-mt-1'>Check In: <DateView millis={timestamp} /></div> : null
                }
              </div>
              {
                staff_checkin_status === CHECKIN_STATUS.CHECKED_IN
                ? <div className='tw-flex tw-items-center tw-cursor-pointer'
                    onClick={() => setShowRoutePlan(true)}
                  >
                    <FontAwesomeIcon icon={faMapLocationDot} width={22}/>
                    <span className="tw-ml-1 tw-text-sm tw-font-bold">Today&apos;s Route Plan</span>
                  </div>
                : null
              }
            </>
          )
        }
        <div className='tw-flex tw-items-center tw-cursor-pointer' onClick={onShowChatSession}>
          <FontAwesomeIcon icon={faEnvelope} width={22}/>
          <span className="tw-ml-1 tw-text-sm tw-font-bold">Messenger</span>
        </div>
        {
          !is_subscribed  && is_push_supported
            ?
              <div className='tw-flex tw-items-center tw-cursor-pointer' onClick={requestWebPush}>
                <FontAwesomeIcon icon={faBell} width={22}/>
                <span className="tw-ml-1 tw-text-sm tw-font-bold">Subscribe</span>
              </div>
            : null
        }
        <div className='tw-flex tw-items-center tw-cursor-pointer' onClick={onShowUserProfile}>
          <FontAwesomeIcon icon={faCircleUser} width={16} />
          <span className="tw-ml-1 tw-text-sm">{user.name.substr(0, 10)}</span>
        </div>
      </>
    ), [staff_checkin_status, user]);

    return (
      <div className={`tw-flex tw-items-center tw-gap-6 ${className || 'tw-text-gray-600'}`}>
        {
          shop_page_points_wallet && shop_page_points_wallet.wallet_amount > 0
          ? <div className='tw-flex tw-border tw-bg-gray-100 tw-px-2 tw-rounded tw-items-center tw-cursor-pointer'
              onClick={showPointsInfo}
            >
              {getDisplayPrice(shop_page_points_wallet.wallet_currency, shop_page_points_wallet.wallet_amount * 1000)}
            </div>
          : null
        }
        {!isInStandaloneMode() && can_install ? 
          <div className='tw-flex tw-items-center tw-cursor-pointer' onClick={requestAppInstall}>
            <FontAwesomeIcon icon={faCloudDownload} width={22}/>
            <span className="tw-ml-1 tw-text-sm tw-font-bold max-md:tw-hidden">Install App</span>
          </div>
          : null
        }
        {user
          ? !screen.md
            ? <>
                <FontAwesomeIcon icon={faBars} onClick={() => setShowMenu(!show_menu)} />
                <SideSheet show={show_menu} onClose={() => setShowMenu(false)}>
                  <div className='tw-whitespace-nowrap tw-px-4 tw-flex tw-flex-col-reverse tw-items-start header-menu-list'>
                    <MenuItems />
                  </div>
                </SideSheet>
              </> 
            : <MenuItems />
          : <FontAwesomeIcon icon={faUserAlt} onClick={onLogin} />
        }
        <ResponsivePopup show={show_route_plan} is_full_screen={true} title={'Today\'s Route Plan'} onClose={() => setShowRoutePlan(false)}>
          <RoutePlanStaffView delivery_route_id={checkin_route_id} />
        </ResponsivePopup>
      </div>
    );
}

export default HeaderNav