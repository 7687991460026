import React, { useEffect, Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals, { sendWebVitalsToAnalytics } from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route, useLocation, matchPath, matchRoutes, Link } from "react-router-dom";
import { FileUploaderPage } from "base/file_uploader";
import { broadcaster, setBroadcastHookState, useBroadcastedState } from "base/utils/events";
import { IS_DEV, ViewPort } from "base/constants";
import "./css/app.css";
import "./css/variables.css";
import { UserActionIcons } from "./user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { screens } from "tailwindcss/defaultTheme";
import { cache } from "./user";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

import { Popup } from "base/ui/popups";
import { onServiceWorkerUpdateFound } from "base/ui/misc";
import "base/css/w3.css";
import DeliveryRoutesList from "./pages/DeliveryRoutesList";
import { useStaffLocation } from "./useStaffLocation";
import "base/utils/polyfills/objpolyfills";
import { LoadingView, WarningView } from "base/ui/status";
import { WalletLedgerEntries } from "base/payments";
import QRScan from "./pages/QRScan";
import QuickboksCallback from "./pages/QuickbooksCallback";
import AreaRoutes from "./pages/AreaRoutes";
import { getByIds } from "base/get_by_ids";
import EventTracker from "./eventsTracker";
import WhatsAppButton from "./components/features/widget/WhatsAppButton";

/* static auth key */
const urlParams = new URLSearchParams(window.location.search);
const auth_key = urlParams.get("auth_key");
if (auth_key) {
	document.cookie = `auth_key=${auth_key}; path=/;`;
	window.location.href = window.location.origin + window.location.pathname;
}
window.CustomPricingCache = {}; // until refresh

broadcaster.add_event_listener("error:need_user", () => {
	Popup.show("Login Required", <WarningView height={200} />, {
		ok_button: "Login",
		cb: (action) => {
			action && broadcaster.broadcast_event("do_login");
		},
	});
	return true; // handled
});

/* SETTING UP BASE */
WalletLedgerEntries.endpoint = "/api/admin/wallet/ledger";
getByIds.addCaching("delivery_route_ids", "delivery_routes", "_id");

function Manifest() {
	const { pathname } = useLocation();
	useEffect(() => {
		let manifest_file = "/manifest.json";
		if (matchPath("/admin/*", pathname)) {
			manifest_file = "/manifest-admin.json";
		} else if (matchPath("/delivery/*", pathname)) {
			manifest_file = "/manifest-delivery.json";
		}
		document.querySelector("#manifest-json").setAttribute("href", manifest_file);
	}, [pathname]);
}

function Header() {
	/* only updated after init or other user loading call, deliberately set like that */
	const [shop_info] = useBroadcastedState("shop_info", "");
	const [delivery_org_id] = useBroadcastedState("delivery_org_id");
	const [delivery_route_id] = useBroadcastedState("delivery_route_id");
	const [user] = useBroadcastedState("_user_", null);

	const { pathname } = useLocation();
	const home_path = matchPath("/", pathname);
	const landing_path = matchPath("/landing/*", pathname);
	const app_route_path = matchPath(`/${delivery_route_id}`, pathname);
	const admin_route_path = matchPath("/admin/route/:delivery_route_id/*", pathname);
	const is_admin_route_child_page = !!admin_route_path?.params?.["*"];
	const [screen] = useBroadcastedState("tw_screen_size");
	useStaffLocation();
	const landing_paths = [
		"/home",
		"/b2b",
		"/b2c",
		"/industries",
		"/partnerships",
		"/contactus",
		"/thankyou",
		"/bookdemo",
		"/sme",
		"/large-enterprises",
		"/customer-stories",
		"/customer-stories/*",
		"/pricing",
		"/whatsapp-button-generator",
		"/shops",
		"/admin/*",
	];
	const matched_landing_path = landing_paths.find((path) => matchPath(path, pathname));

	const is_landing_page = (home_path && !delivery_route_id) || landing_path || matched_landing_path;
	const is_shop_page = (home_path && delivery_route_id) || app_route_path;
	const is_user_manager =
		user?.roles?.superadmin || user?.roles?.manager?.length || user?.roles?.delivery_org_manager?.length;

	// Hide header for landing page
	if (is_landing_page) return;
	// Hide header for admin child routes in small screens - Orders pages, customers page etc.,
	if (!screen.lg && is_admin_route_child_page) return;

	if (user) {
		// EventTracker user identification
		EventTracker.identify(user?._id);
		// EventTracker.track("User detected");
		EventTracker.people.set({
			$name: user?.name,
			$phone_number: user?.phone_number,
			$delivery_org_id: delivery_org_id,
			$user_roles: user?.roles && Object.keys(user?.roles)?.length ? Object.keys(user?.roles) : [],
		});
	}

	return (
		<div
			className={`tw-items-center tw-justify-between tw-z-10 tw-pl-1 tw-pr-4 tw-h-12 md:tw-h-14 tw-flex ${is_shop_page ? "tw-bg-primary-bg" : ""}`}
		>
			<div className="tw-flex items-center">
				{admin_route_path || is_user_manager ? (
					<Link to={`/admin/${delivery_org_id || ""}`} className="tw-px-2 tw-flex tw-items-center">
						<FontAwesomeIcon icon={faChevronLeft} />
					</Link>
				) : null}
				{
					<div
						className="tw-ml-2 tw-whitespace-nowrap tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
						onClick={() => (window.location.href = "/")}
					>
						{shop_info?.logo || <FontAwesomeIcon icon={faTruckFast} className="tw-text-md tw-text-gray-500 tw-mr-2" />}
						<span className="tw-capitalize tw-text-md md:tw-text-lg tw-font-bold tw-text-primary ">
							{shop_info.title || "Sukhiba"}
						</span>
					</div>
				}
			</div>
			<UserActionIcons />
		</div>
	);
}

function getScreensData() {
	return Object.entries(screens).reduce((result, [name, width]) => {
		const max_width = Number(width.split("px")[0]) - 1;

		const match_width = window.matchMedia(`(min-width: ${width})`);
		const match_max_width = window.matchMedia(`(max-width: ${max_width}px)`);

		result[name] = match_width?.matches;
		result[`max-${name}`] = match_max_width?.matches;

		return result;
	}, {});
}

// will be deregistered on window close
function onWindowResize() {
	setBroadcastHookState("tw_screen_size", getScreensData());
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}

broadcaster.add_event_listener("viewport", onWindowResize);

const LazyAdminPage = React.lazy(() => import("./admin"));
const LazyDeliveryPage = React.lazy(() => import("./delivery"));
const LazyStaffPage = React.lazy(() => import("./staff"));
const LazyWaConnectPage = React.lazy(() => import("./pages/WaConnect"));
const LazyQuickbooksCallbackPage = React.lazy(() => import("./pages/QuickbooksCallback"));
const LazyLandingPage = React.lazy(() => import("./pages/LandingPage"));
const LazyBookDemo = React.lazy(() => import("./pages/landing/BookDemo"));

function SuspenseLoading() {
	return <LoadingView height={ViewPort.HEIGHT + "px"} />;
}

function RouterStack() {
	// do initial screen measurement
	onWindowResize();

	return (
		<Router>
			<Manifest />
			<Header />
			<Routes>
				{/* main */}
				<Route path="/" element={<App />} exact />
				<Route path="/:delivery_route_id" element={<App />} exact />
				{window.location.hostname.endsWith("sukhiba.com") || IS_DEV ? (
					<Route
						path="/landing/*"
						element={
							<Suspense fallback={<SuspenseLoading />}>
								{" "}
								<LazyLandingPage />{" "}
							</Suspense>
						}
						exact
					/>
				) : (
					<Route path="/landing" element={<DeliveryRoutesList />} exact />
				)}

				{[
					"bookdemo",
					"whatsapp-ecommerce-store",
					"whatsapp-shopify-store",
					"whatsapp-woocommerce-store",
					"bulk-whatsapp-message",
				].map((path) => (
					<Route
						key={path}
						path={path}
						element={
							<Suspense fallback={<SuspenseLoading />}>
								<LazyBookDemo />
							</Suspense>
						}
					/>
				))}
				{[
					"home",
					"b2b",
					"b2c",
					"industries",
					"sme",
					"large-enterprises",
					"partnerships",
					"contactus",
					"thankyou",
					"pricing",
					"customer-stories",
					"customer-stories/*",
				].map((path) => (
					<Route
						key={path}
						path={path}
						element={
							<Suspense fallback={<SuspenseLoading />}>
								{" "}
								<LazyLandingPage />{" "}
							</Suspense>
						}
					/>
				))}
				<Route
					path="/wa-connect"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyWaConnectPage />
						</Suspense>
					}
					exact
				/>
				<Route
					path="/quickbooks/callback"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							{" "}
							<LazyQuickbooksCallbackPage />{" "}
						</Suspense>
					}
					exact
				/>

				<Route path="/area" element={<AreaRoutes />} exact />
				<Route path="/qr" element={<QRScan />} exact />

				{/* file uploader */}
				<Route path="/upload" element={<FileUploaderPage />} exact />

				<Route
					path="/admin/*"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyAdminPage />
						</Suspense>
					}
				/>

				<Route path="/tnc/*" element={<TermsAndConditions />} />

				<Route path="/privacy_policy/*" element={<PrivacyPolicy />} />
				{/* Customer Org Routes List Page */}
				<Route path="/dorg/:delivery_org_id" element={<DeliveryRoutesList />} />
				<Route path="/shops/:delivery_org_id" element={<DeliveryRoutesList />} />
				<Route path="/dorg" element={<DeliveryRoutesList />} />
				<Route path="/shops" element={<DeliveryRoutesList />} />
				<Route path="/whatsapp-button-generator" element={<WhatsAppButton />} />

				{/* delivery staff page */}
				<Route
					path="/delivery/"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyDeliveryPage />
						</Suspense>
					}
					exact
				/>
				<Route
					path="/delivery/:delivery_route_id"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyDeliveryPage />
						</Suspense>
					}
					exact
				/>
				<Route
					path="/delivery/:delivery_route_id/:delivery_date"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyDeliveryPage />
						</Suspense>
					}
					exact
				/>
				<Route
					path="/staff/*"
					element={
						<Suspense fallback={<SuspenseLoading />}>
							<LazyStaffPage />
						</Suspense>
					}
				/>
			</Routes>
			<Popup />
		</Router>
	);
}

const RootNode = () => (
	<React.StrictMode>
		<RouterStack />
	</React.StrictMode>
);

const root_element = document.getElementById("root");
const root = ReactDOM.createRoot(root_element);
root.render(<RootNode />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
IS_DEV
	? serviceWorkerRegistration.unregister()
	: serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdateFound });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitalsToAnalytics);
