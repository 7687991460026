import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { broadcaster } from 'base/utils/events';
import { PHONE_NUMBER_CODES } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useRerender } from 'base/utils/common';

const OtpLoginForm = ({delivery_route_id = null}) => {
    const [form_data, setFormData] = useState({country_phone_code: '254'}) // phone_number, country_phone_code, otp, name
    const [login_data, setLoginData] = useState() // otp_sent, need_name, user
    const [errors, setErrors] = useState(); // invalid_phone_number, otp_expired, invalid_otp
    const rerender = useRerender()

    const disable_get_otp = !form_data?.country_phone_code || !form_data?.phone_number || errors?.invalid_phone_number;
    const disable_login = !form_data?.otp || (login_data?.need_name && !form_data?.name) || errors?.otp_expired || errors?.invalid_otp;

    const phoneLogin = () => {
        if (login_data?.otp_sent ? !disable_login : !disable_get_otp) {
            axios.post(`/api/user/login/`, {delivery_route_id, ...form_data}).then((resp) => {
                if (resp.data.errors) {
                    setErrors(resp.data.errors)
                    return;
                }
                if (resp.data.user) {
                    broadcaster.broadcast_event("_user_", resp.data.user);
                    return;
                }
                setLoginData(resp.data);
            })
        }
    }

    const onFormDataChange = (key) => {
        return (evt) => {
            setFormData({...form_data, [key]: evt.target.value});
            setErrors(null)
        }
    }

    useEffect(() => {
        axios.get("/user/location").then(
            (resp) => {
                if (resp.data && PHONE_NUMBER_CODES.includes(resp.data.phone_code)) {
                    form_data.country_phone_code = resp.data.phone_code
                    rerender()
                }
            }
        )
    }, [])

    const goBack = () => {
       setLoginData(null);
       setFormData({...form_data, otp: null, name: null});
    }

    return (
        !login_data?.otp_sent
            ?   <div className='tw-mt-8'>
                    <div className='tw-text-lg tw-mb-2 font-inter'>Enter your WhatsApp Phone Number</div>
                    <div className='tw-text-xs tw-text-gray-500'>Choose a valid country code and enter mobile number</div>
                    <div className='tw-rounded tw-border tw-flex tw-p-1 tw-w-full tw-mt-6'>
                        <select className="tw-outline-none tw-text-md w3-padding-8 tw-mr-1" 
                            onChange={onFormDataChange('country_phone_code')}
                            value={form_data?.country_phone_code}
                        >
                            {PHONE_NUMBER_CODES.map(code => (
                                <option key={code} value={code}>+{code}</option>
                            ))}
                        </select>
                        <input type='number' className='tw-flex-1 tw-outline-0 tw-text-md tw-border-l tw-pl-4 tw-appearance-none' 
                            placeholder='Phone Number' 
                            defaultValue={form_data?.phone_number}
                            onChange={onFormDataChange('phone_number')} 
                        />
                    </div>
                    {errors?.invalid_phone_number ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter a valid Phone Number</div> : null}
                    <button
                        className={`tw-bg-yellow-500 tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8`}
                        onClick={() => disable_get_otp ? setErrors({invalid_phone_number: true}) : phoneLogin()}
                    >Send OTP</button>
                </div>
                :
                <div>
                    <div className='tw-mb-8 tw-cursor-pointer' onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} className='tw-p-2 tw--m-2' /></div>
                    <div className='tw-text-lg tw-mb-2 font-inter'>Verify OTP</div>
                    <div className='tw-text-xs tw-text-gray-500'>Enter the OTP sent on your <span className='tw-font-medium tw-text-gray-600'>WhatsApp No: +{form_data.country_phone_code} {form_data.phone_number}</span></div>
                    <div className='tw-rounded tw-border tw-px-4 tw-py-2 tw-w-full tw-mt-6'>
                        <input type='number' autoFocus={true} className='tw-outline-0 tw-text-md tw-w-full tw-appearance-none' 
                            placeholder='OTP' 
                            onChange={onFormDataChange('otp')} 
                        />
                    </div>
                    {errors?.otp_expired ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Your OTP has expired!</div> : null}
                    {errors?.invalid_otp ? <div className='tw-text-red-600 tw-text-xs tw-mt-1'>Please enter correct OTP.</div> : null}
                    {login_data?.need_name
                    ?   <div className='tw-rounded tw-border tw-px-4 tw-py-2 tw-w-full tw-mt-6'>
                            <input type='text' className='tw-outline-0 tw-text-md tw-appearance-none' 
                                placeholder='Name' 
                                onChange={onFormDataChange('name')} 
                            />
                        </div>
                    : null
                    }
                    {errors?.invalid_name && login_data?.need_name ? <div className='tw-text-red-600 tw-text-xs'>Please enter a valid Name.</div> : null}
                    <button
                        className={`tw-bg-yellow-500 tw-w-full tw-py-2 tw-rounded tw-text-sm tw-mt-8 tw-font-bold tw-tracking-wide`}
                        onClick={() => disable_login ? setErrors({invalid_otp: !form_data.otp, invalid_name: !form_data.name}) : phoneLogin()}
                    >
                        SUBMIT
                    </button>
                </div>
  )
}

export default OtpLoginForm