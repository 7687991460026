import React, { useState } from "react";
import { createPortal } from "react-dom";
import { ToolTip } from "./misc";
import { useRerender } from "../utils/common";

export const DO_NOT_CLOSE_POPUP = Object();

/* zIndex: sideSheet -> 50, Model, toast -> 40  */
function Popup() {
  Popup.refresh = useRerender();
  return Popup.portals;
}
Popup.portals = [];

function RefreshableViews({ ctx }) {
  const rerender = useRerender();
  ctx.refresh = rerender;
  return Object.values(ctx.views);
}

function createPopupsContainer(parent, _key, prepend) {
  parent = parent || document.body;
  _key = _key || "_popups";
  // create a popup react element
  var ctx = parent[_key];
  if (!ctx) {
    let popups_container_el = document.createElement("DIV");
    prepend
      ? parent.prepend(popups_container_el)
      : parent.append(popups_container_el);
    ctx = parent[_key] = {
      views: {},
      el: popups_container_el,
      add(key, popup) {
        this.views[key] = popup;
        this.refresh();
      },
      remove(key) {
        delete this.views[key];
        this.refresh();
      },
      removeAll() {
        this.views = {};
        this.refresh();
      },
      refresh() {},
    };
    // add a react node to the container
    Popup.portals.push(
      createPortal(<RefreshableViews ctx={ctx} />, popups_container_el)
    );
    Popup.refresh();
  }
  return ctx;
}

function ModalWindow({
  title,
  content,
  ok_button,
  cancel_button,
  action_cb,
  auto_cancel,
  className,
}) {
  return (
    <div className={`w3-modal w3-row ${className}`}>
      <div
        className="w3-occupy"
        onClick={auto_cancel ? () => action_cb(0) : null}
      ></div>
      <div
        className="w3-display-middle w3-margin-topbottom-16 w3-bounded w3-col s11-5"
        style={{ maxWidth: "800px" }}
      >
        <div
          className="w3-white w3-2px-shadow w3-bounded w3-expand"
          style={{ borderRadius: "12px", overflow: "hidden" }}
        >
          <header className="w3-relative w3-large w3-bold">
            <span
              className="w3-padding-8-16 w3-pointer w3-display-topright"
              onClick={() => action_cb(0)}
            >
              &times;
            </span>
            {title ? (
              <div
                className="w3-capitalize"
                style={{ padding: "16px 32px 0 16px" }}
              >
                {title}
              </div>
            ) : null}
          </header>
          {content ? <div className="w3-row">{content}</div> : null}
          <div className="w3-light-grey footer_container w3-right-align w3-list-horizontal-16 w3-padding-sides-16">
            {ok_button ? (
              <div
                className="w3-margin-topbottom-8 w3-button w3-2px-shadow w3-array w3-green "
                onClick={() => action_cb(1)}
              >
                {ok_button}
              </div>
            ) : null}
            {cancel_button ? (
              <div
                className="w3-margin-topbottom-8 w3-button w3-array w3-white cancel_button"
                onClick={() => action_cb(0)}
              >
                {cancel_button}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function UiBlock({ content: _content, ctx }) {
  const [content, setContent] = useState(_content);
  ctx.setContent = setContent;

  return (
    <div className="w3-modal w3-row">
      <div className="w3-occupy">
        <div className="w3-expand w3-relative">
          <div className="w3-display-middle w3-text-white w3-center">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}

function SlideUpContent({ content, title, ctx, onCancel }) {
  const doCancel = (evt) => {
    if (!onCancel || onCancel() !== false) {
      ctx.close();
    }
  };

  return (
    <div className="w3-modal w3-row">
      <div className="w3-occupy" onClick={doCancel}></div>
      <div
        className="w3-display-bottommiddle w3-bounded w3-animate-slide-in-from-bottom"
        style={{ width: "600px" }}
      >
        <div
          className="w3-row w3-text-white w3-flex"
          style={{ maxHeight: "75px" }}
        >
          <div
            className="w3-col s9 w3-bold w3-padding-8"
            style={{ marginTop: "auto" }}
          >
            {title}&nbsp;
          </div>
          <div className="w3-col s3">
            <div
              className="w3-xlarge w3-padding-8-16 w3-right-align"
              onClick={doCancel}
            >
              X
            </div>
          </div>
        </div>
        <div
          className="w3-white w3-relative w3-scroll"
          style={{
            paddingBottom: "30px",
            maxHeight: `${parseInt(window.innerHeight - 105)}px`,
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

function SideSheet({ content, title, ctx, onCancel, direction, style }) {
  const [animate_class, setAnimateClass] = useState(
    `w3-bounded w3-animate-slide-in-from-${direction}`
  );
  const doCancel = (evt) => {
    if (!onCancel || onCancel() !== false) {
      setAnimateClass(`w3-bounded w3-animate-slide-out-to-${direction}`);
      setTimeout(() => ctx.close(), 300);
    }
  };

  return (
    <div
      className="w3-modal w3-row w3-overflow-hidden"
      style={{ zIndex: 20, ...style }}
    >
      <div className="w3-occupy" onClick={doCancel}></div>
      <div
        className={`w3-col l6 m10 s12 w3-white w3-scroll w3-expand-y w3-display-${direction} ${animate_class}`}
      >
        <div className="w3-white w3-relative">
          <div className="w3-row w3-flex" style={{ maxHeight: "75px" }}>
            <div
              className="w3-col s9 w3-bold w3-padding-8"
              style={{ marginTop: "auto" }}
            >
              {title}&nbsp;
            </div>
            <div className="w3-col s3">
              <div
                className="w3-xlarge w3-padding-8-16 w3-right-align"
                onClick={doCancel}
              >
                X
              </div>
            </div>
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}

/* POPUP METHODS */
Popup.show = Popup.open = (title, content, options) => {
  const { container, ok_button, cancel_button, cb, remove_others, className } =
    options || {};
  const popups = createPopupsContainer(container);
  if (remove_others) {
    popups.removeAll();
  }

  const popup_id = new Date().getTime();
  popups.add(
    popup_id,
    <ModalWindow
      title={title}
      content={content}
      ok_button={ok_button}
      cancel_button={cancel_button}
      key={popup_id}
      className={className || ""}
      action_cb={(action) => {
        // close it, remove and update popups
        if (!cb || cb(action) !== DO_NOT_CLOSE_POPUP) {
          popups.remove(popup_id);
        }
      }}
      auto_cancel={true}
    />
  );
  return {
    close: () => {
      cb && cb(0);
      popups.remove(popup_id);
    },
  };
};

Popup.blockUi = function (content, options) {
  const { container, remove_others } = options || {};
  const popups = createPopupsContainer(container);

  if (remove_others) {
    popups.removeAll();
  }
  var ctx = {};
  const popup_id = new Date().getTime();
  popups.add(popup_id, <UiBlock content={content} ctx={ctx} key={popup_id} />);

  ctx.remove = () => {
    popups.remove(popup_id);
  };
  return ctx;
};

Popup.slideUp = function (content, options) {
  const { title, onCancel, container, remove_others } = options || {};
  const popups = createPopupsContainer(container);
  if (remove_others) {
    popups.removeAll();
  }

  var ctx = {};

  const popup_id = new Date().getTime();
  popups.add(
    popup_id,
    <SlideUpContent
      content={content}
      title={title}
      onCancel={onCancel}
      ctx={ctx}
      key={popup_id}
    />
  );

  ctx.close = () => {
    popups.remove(popup_id);
  };
  return ctx;
};

Popup.sideSheet = function (content, options) {
  const { title, onCancel, container, remove_others } = options || {};
  const popups = createPopupsContainer(container);
  if (remove_others) {
    popups.removeAll();
  }

  var ctx = {};

  const popup_id = new Date().getTime();
  popups.add(
    popup_id,
    <SideSheet
      content={content}
      title={title}
      onCancel={onCancel}
      ctx={ctx}
      key={popup_id}
      direction={options?.direction || "right"}
      style={options?.style || {}}
    />
  );

  ctx.close = () => {
    popups.remove(popup_id);
  };
  return ctx;
};

Popup.showContextMenu = (target, contents, options, container) => {
  container = container || document.body;
  let ctx = {};
  /* settimeout because we let the clicklistener on body */
  const popups = createPopupsContainer(container);
  if (target._is_menu_open) {
    popups.remove(target._is_menu_open);
    target._is_menu_open = null;
    return;
  }

  if (options?.remove_others) {
    popups.removeAll();
  }
  const popup_id = new Date().getTime();
  target._is_menu_open = popup_id;
  setTimeout(() => {
    popups.add(
      popup_id,
      <ToolTip
        target={target}
        container={container}
        ctx={ctx}
        key={popup_id}
        options={options}
      >
        {contents}
      </ToolTip>
    );
  }, 0);

  ctx.close = () => {
    popups.remove(popup_id);
    target._is_menu_open = null;
  };
  return ctx;
};

Popup.hint = (content) => {
  const popups = createPopupsContainer(null, "_hints", true);

  var ctx = {};

  const popup_id = new Date().getTime();
  popups.add(popup_id, content);
  ctx.close = () => {
    popups.remove(popup_id);
  };
  return ctx;
};

var toasts_container = null;
Popup.toast = (content, timeout, className, max_toasts, gravity) => {
  if (timeout === undefined) timeout = 4000;
  if (!toasts_container) {
    toasts_container = createPopupsContainer(null, "_toasts");
    toasts_container.el.className += `${
      gravity === "left"
        ? "w3-display-bottomleft w3-margin-left"
        : "w3-display-bottommiddle"
    } w3-list`;
    toasts_container.el.style.bottom = "30px";
    toasts_container.el.style.zIndex = 40;
    toasts_container.el.style.maxWidth = "400px";
  }

  if(max_toasts){
    let view_ids = Object.keys(toasts_container.views);
    while(view_ids.length > max_toasts){
      toasts_container.remove(view_ids.shift());
    }
  }

  var ctx = {};
  const popup_id = new Date().getTime();
  toasts_container.add(
    popup_id,
    <div
      className={`w3-flex-inline w3-flex-vcenter w3-padding-8-16 w3-transclucent-black w3-round ${className}`}
      key={popup_id}
    >
      {content}
      {timeout && timeout <= 0 ? (
        <div
          className="w3-button w3-margin-left w3-green w3-round-xxlarge w3-right"
          onClick={() => toasts_container.remove(popup_id)}
        >
          close
        </div>
      ) : null}
    </div>
  );

  ctx.close = () => {
    toasts_container.remove(popup_id);
  };
  timeout && timeout > 0 && setTimeout(() => ctx.close(), timeout);
  return ctx;
};

export { Popup, createPopupsContainer };
