import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Fuse from 'fuse.js'
import { LoadingView } from "base/ui/status";
import WhatsappIcon from "../images/whatsapp.svg"
import { openWhatsapp } from "base/utils/common";
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategorySelectorStrip } from "base/ui/misc";
import bgBoxes from "../images/landing/boxes_bg.svg";
import SukhibaLogo from "../images/ic_logo_sukhiba.svg";

function DeliveryRoutesList() {
  const params = useParams();
  const delivery_org_id = params?.delivery_org_id || window.delivery_org_id || "sukhiba";
  const [delivery_org, setDeliveryOrg] = useState(null); // [delivery_org_id
  const [delivery_routes, setDeliveryRoutes] = useState([]);
  const [filtered_delivery_routes, setFilteredDeliveryRoutes] = useState([]);
  const [is_loading, setLoading] = useState(false);
  const [search_text, setSearchText] = useState("");
  const [all_categories, setAllCategories] = useState([]); // [category_id]
  const fetchDeliveryRoutesList = () => {
    if (is_loading) return;
    setLoading(true);
    axios
      .get(`/api/dorg/${delivery_org_id}`)
      .then((resp) => {
        if (resp.data.errors) {
          return;
        }
        setDeliveryOrg(resp.data?.delivery_org);
        setDeliveryRoutes(resp.data?.delivery_routes);
        setFilteredDeliveryRoutes(resp.data?.delivery_routes);
        /* resp.data.delivery_routes[x].categories , concat, and remove duplicates */
        let categories = [];
        resp.data?.delivery_routes?.forEach((route) => {
          if (route.categories) {
            categories = categories.concat(route.categories);
          }
        });
        setAllCategories([...new Set(categories)]);
        DeliveryRoutesList.search_index = new Fuse(
          resp.data.delivery_routes,
          { "keys": ["title", "description", "categories"], includeScore: true }
        );
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => { fetchDeliveryRoutesList() }, []);

  useEffect(
    () => {
      if (search_text) {
        setFilteredDeliveryRoutes(
          DeliveryRoutesList.search_index.search(search_text).map((r) => r.item)
        );
      } else {
        setFilteredDeliveryRoutes(delivery_routes)
      }
    }, [search_text]
  )

  if (!delivery_org) {
    return <LoadingView title={"Loading.."} height="300px" />
  }

  return (
    <div className="tw-scroll-smooth tw-scrollbar-none tw-bg-[#fdfaf0]">
      <div className="tw-flex tw-justify-between tw-items-center tw-p-4">
        <div className="tw-cursor-pointer" onClick={() => {
          window.open("/home", "_self");
        }}>
          <img src={SukhibaLogo} className="tw-h-[33px]" />
        </div>
        <button
          className="tw-rounded-full tw-bg-landing-green tw-text-white tw-text-xs tw-px-4 tw-py-2 tw-flex tw-gap-2 tw-items-center"
          onClick={() => {
            window.open("/contactus", "_self");
          }}
        ><img src={WhatsappIcon} width={22} />
          {"Get Started with Sukhiba"}
        </button>
      </div>
      <div className="tw-relative tw-w-full tw-px-2 tw-py-2 tw-grid tw-grid-cols-1 tw-justify-items-center tw-text-center">
        <h1 className="tw-font-bold max-md:tw-leading-tight">Search WhatsApp businesses near you</h1>
        <h4 className="tw-font-light tw-my-3 tw-text-md">Try out these how following businesses wok on Whatsapp. Contact us to get started.</h4>

        <div className='tw-relative tw-bg-white tw-rounded-full tw-text-sm tw-px-4 tw-py-3 tw-flex tw-items-center md:tw-w-1/3 tw-my-2'>
          <FontAwesomeIcon icon={faSearch} className='tw-text-gray-400' />
          <input className='tw-outline-0 tw-ml-2 tw-text-md tw-w-full'
            value={search_text}
            onChange={
              (evt) => {
                setSearchText(evt.target.value);
              }
            }
            placeholder="Search for Business"
          />
          {
            search_text
              ? <FontAwesomeIcon icon={faX} className='tw-absolute tw-right-3 tw-text-white tw-text-xxs' onClick={() => setSearchText("")} />
              : null
          }
        </div>
      </div>
      {
        all_categories?.length > 0 &&
        <CategorySelectorStrip categories={all_categories} onCategoriesSelected={(categories) => {
          if (categories.length > 0) {
            setFilteredDeliveryRoutes(
              delivery_routes.filter(
                (d) => d.categories?.some(
                  c => categories.some(_c => c.toLowerCase().includes(_c))
                )
              )
            )
          } else {
            setFilteredDeliveryRoutes(delivery_routes)
          }
        }} />
      }
      {filtered_delivery_routes.length > 0 ? (
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-px-4 tw-gap-4 tw-pb-4">
          {filtered_delivery_routes?.map((item, i) => (
            <div
              key={item._id} className={`tw-bg-white tw-rounded-xl tw-p-4 tw-relative tw-h-full`}>
              <img src={item.image ||
                "https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2023-04-17/168171844004tRG191tR__SUKHIBA_MASCOTVf.jpeg"}
                className=' tw-h-16 tw-rounded-md' />
              <div>
                {
                  item.is_private && <div className='tw-absolute tw-top-1 tw-text-white tw-rounded-l tw-right-0 tw-text-xxs tw-p-1 tw-bg-red-400'>
                    PRIVATE
                  </div>
                }
                <p className='tw-text-black tw-text-sm tw-font-bold tw-mt-4'>
                  {item.title}
                </p>
                <p className='tw-text-[#666666] tw-text-xs tw-mt-2 line-clamp-2 tw-min-h-[30px]'>
                  {item.description}
                </p>
              </div>
              <button className="btn-secondary tw-rounded-full tw-w-full tw-mt-2"
                onClick={() => window.open(`/${item._id}`, "_blank")}>Visit Shop
              </button>
            </div>
          ))}
        </div>
      ) : is_loading ? (
        <LoadingView title={"Loading.."} height="200px" />
      ) : null}

      <div
        className="tw-p-12 tw-text-landing-black tw-text-center tw-bg-[#052F2F] tw-grid tw-justify-items-center"
        style={{ backgroundImage: "url(" + bgBoxes + ")", backgroundRepeat: "repeat" }}
      >
        <h1 className="tw-text-[28px] md:tw-text-[36px] tw-font-semibold lg:tw-whitespace-pre tw-leading-tight tw-text-white">
          {"See what Sukhiba can do for your\nbusiness on WhatsApp"}
        </h1>
          <button
            className="tw-rounded-full tw-mt-4 tw-bg-landing-green tw-text-white tw-px-9 tw-py-3.5 tw-flex tw-gap-2 tw-items-center"
            onClick={() => {
              window.open("/contactus", "_self");
            }}
          ><img src={WhatsappIcon} width={22} />
            {"Get Started with Sukhiba"}
          </button>
      </div>

      {/* <div className="tw-absolute tw-bottom-12 tw-flex tw-p-2 tw-items-center tw-right-0 tw-bg-gray-100 tw-rounded-l-xl"
        onClick={() => { openWhatsapp(delivery_org.wa_business_number, "Hey, i'm looking for something") }}>
        <img src={WhatsappIcon} width={34} />
      </div> */}
    </div>
  );
}

export default DeliveryRoutesList;
