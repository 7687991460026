import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Popup } from 'base/ui/popups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags, faX } from '@fortawesome/free-solid-svg-icons';
import { GenericException } from 'base/ui/errors';
import { getByIds } from 'base/get_by_ids';
import { SuggestedField } from 'base/ui/suggested_field';
import { useRerenderWithValue } from 'base/utils/common';
import { UserFullDetails } from '../../../csm/user';
import EventTracker from '../../../eventsTracker';

/**
 * A component for editing and displaying a list of tags.
 *
 * @param {Array} _tags - The initial list of tags.
 * @param {Function} onSelectedTagsUpdated - A callback function that is called when the selected tags are updated.
 *
 * @returns {JSX.Element} - The rendered TagsEditor component.
 */
function TagsEditor({ _tags, onSelectedTagsUpdated, delivery_route_id }) {
    // const ctx = useRef({}).current
    const [tags, setTags] = useState(_tags || []);
    const [suggested_tags, setSuggestedTags] = useState()
    const [_refresh, rerender] = useRerenderWithValue()

    useEffect(() => {
        if (delivery_route_id) {
            getByIds({ "delivery_route_ids": [delivery_route_id] }).then(
                (data) => {
                    setSuggestedTags(data.delivery_routes[delivery_route_id]?.data.additional_tags || [])
                    rerender()
                }
            )
        }
    }, [])

    return <div className='tw-p-4'>
        <div className='tw-flex tw-gap-2 tw-text-sm '>
            <div className='tw-w-full'>
                <SuggestedField key={_refresh} className=" tw-grow tw-bg-transparent tw-outline-0"
                    selection_list={suggested_tags}
                    props={{
                        "placeholder": "Type and select Tag",
                        "result_renderer": (item) => <p className="tw-p-2">{item}</p>,
                        "selected_renderer": (item, onRemove) => <div className="tw-bg-[#FEEBC8] tw-text-[#DD6B21] tw-flex tw-px-1 tw-rounded-xl tw-items-center tw-mt-1 tw-px-2 tw-mr-1">
                            <span className="tw-text-sm tw-text-center tw-text-secondary">{item}</span>
                            <span className='tw-text-lg tw-text-black-500 tw-pl-2 tw-cursor-pointer' onClick={onRemove}>&times;</span>
                        </div>,
                        "show_results_on_render": false,
                        "hide_no_results": true,
                        "selected_values_classname": "tw-flex tw-border-0 tw-flex-wrap"
                    }}
                    selected={tags}
                    onSelect={(new_tags) => {
                        setTags(new_tags)
                        onSelectedTagsUpdated(new_tags)
                    }}
                    // onSearch={(search_text) => {
                    //     ctx.new_tag = search_text
                    // }}
                    input_className={'tw-rounded tw-text-sm tw-border tw-w-full tw-px-4 tw-py-2'}
                />
            </div>
            {/* <button className='tw-rounded-md tw-bg-green-800 tw-h-max tw-text-white tw-px-4 tw-py-2' onClick={() => {
                const new_tag = ctx.new_tag.trim()
                if (new_tag && !tags.includes(new_tag)) {
                    setTags([...tags, new_tag]);
                    ctx.new_tag = ""
                }
            }}>Add</button> */}
        </div>
    </div>
}

function TagsList({ tags, className, removeSelection }) {
    return <div className={`tw-flex tw-gap-2 tw-overflow-auto w3-hide-scrollbar ${className}`} >
        {
            tags?.map((tag, index) => (
                <div key={index} className='tw-flex tw-gap-2 tw-items-center tw-px-2 tw-rounded-xl tw-py-1 tw-bg-[#FEEBC8] tw-text-[#DD6B21]'>
                    <div className='tw-text-xs tw-font-bold tw-w-max'>{tag}</div>
                    {removeSelection
                        ? <FontAwesomeIcon icon={faX} size="xs"
                            onClick={(evt) => removeSelection(tag)} />
                        : null
                    }
                </div>
            ))
        }
    </div>
}

function UserTags({ ctx, userId, delivery_route_id, tags, onUpdate }) {
    // const [tags, setTags] = useState(_tags)

    function updateTags() {
        axios.post(
            "/session/admin/user/set_tags",
            {
                "session_id": `delivery_route_${delivery_route_id}`,
                "user_id": userId,
                "tags": ctx.new_tags
            }
        ).then(
            (resp) => {
                if (resp.data.errors) {
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />)
                    return;
                }
                // setTags(resp.data.session_user?.tags)
                EventTracker.track("CustomerSupport_Tag_Added")
                onUpdate(resp.data.session_user?.tags)
            }
        );
    }

    const addTags = (tags) => {
        Popup.show(
            "Add Conversational Tags",
            <div className='w3-padding-8'>
                <TagsEditor _tags={tags}
                    delivery_route_id={delivery_route_id}
                    onSelectedTagsUpdated={(tags) => {
                        ctx.new_tags = tags
                        updateTags()
                    }} />
            </div>
        );
    }
    return (
        <div className='w3-text-green w3-pointer' onClick={() => addTags(tags)}>
            +Add/Edit Tags
        </div>
    )
}

function CustomerTagsChatIndicator({ user_id, session_data, delivery_route_id, onUpdate }) {
    delivery_route_id = delivery_route_id || session_data.active_delivery_route_id || session_data.delivery_route_user?.delivery_route_id;
    const ctx = useRef({}).current;
    const [tags, setTags] = useState(session_data.delivery_route_user?.tags)
    const openUserTickets = () => {
		Popup.sideSheet(
			<UserFullDetails org_id={session_data.delivery_org_id} user_id={user_id} />,
            {"style": {"zIndex": "50"}}
		);
	}
    return (
        <div className='tw-z-10 tw-bg-white '>
            <div className='tw-flex tw-text-white tw-text-xs tw-items-center tw-px-4 tw-py-2 tw-gap-4'>
                <UserTags ctx={ctx} userId={user_id} tags={session_data.delivery_route_user?.tags} delivery_route_id={delivery_route_id} onUpdate={(tags) => {
                    setTags(tags)
                    onUpdate && onUpdate(tags)
                }} />
                <div className='tw-cursor-pointer tw-text-xs tw-flex tw-items-center w3-text-green' onClick={openUserTickets}>
                    <FontAwesomeIcon icon={faTags} className='tw-mr-1' />
                    Tickets
                </div>
            </div>
            <TagsList tags={tags} className={"tw-mx-2 tw-mb-2"} />
        </div>
    );
}


CustomerTagsChatIndicator.render = (session_data, onUpdate, delivery_route_id) => {
    const session_id = session_data.session._id
    if (!session_id.startsWith("wcs_")) return null;
    return <CustomerTagsChatIndicator
        user_id={session_id.split("_")[1]}
        session_data={session_data}
        delivery_route_id={delivery_route_id}
        onUpdate={onUpdate}
    />
}

export default CustomerTagsChatIndicator